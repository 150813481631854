import React, { useContext } from "react";
import { AVContext } from "../../utils/AVContext";
import { useIntersectionObserver } from "../../utils/UtilityService";
import en from "../../utils/en.json";
import es from "../../utils/es.json";
import "./WelcomePage.scss";

const WelcomePage = () => {
	const {
		state: { language },
	} = useContext(AVContext);

	const { ref, isVisible, animated } = useIntersectionObserver();

	const translations = language === "en" ? en : es;

	const imgSrc = require("../../utils/images/meet.jpeg");

	return (
		<section id='welcomePage' ref={ref}>
			<div className='avlegalfirm-welcomePage'>
				<div className='avlegalfirm-welcomePage--text'>
					<h1
						className={`${
							isVisible && !animated
								? "bottomToTopAnimation-2"
								: ""
						}`}>
						{translations.welcomeToAV}
					</h1>

					<p
						className={`${
							isVisible && !animated
								? "bottomToTopAnimation-2"
								: ""
						}`}>
						{translations.introduction}
					</p>
				</div>
				<div className='avlegalfirm-welcomePage--img'>
					<img src={imgSrc} alt='AV Law Firm' loading='lazy' />
				</div>
			</div>
		</section>
	);
};

export default WelcomePage;
